<template>
  <v-snackbar v-model="show" :color="color" bottom right style="z-index: 300">
    {{ message }}
    <template #action="{ attrs }">
      <v-btn text v-bind="attrs" @click="show = false"> Close</v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      message: '',
      color: '',
    };
  },

  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'showNotification') {
        this.message = state.notification.content;
        this.color = state.notification.color;
        this.show = true;
      }
    });
  },
};
</script>
